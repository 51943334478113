import React, { useRef, useState } from 'react'
import MoreOptions from './../MoreOptions/MoreOptions'
import { Modal } from 'react-bootstrap'

import styles from './MenuItem.module.css'

const Item = ({ icon, alt, label, onClick, disabled, onUpgrade }) => (
  <div className={`${styles.contentBox} ${disabled ? styles.disabled : ''}`} onClick={!disabled ? onClick : null}>
    <img src={icon} alt={alt} className={styles.img} />
    <span className={styles.contentText}>{label}</span>
  </div>
)

const ContentItem = ({ item, show }) => {
  let content
  switch (item.type) {
    case 'custom_button':
      content = item.component
      break
    default:
      content = <Item {...item} />
  }

  return <div className={styles.contentContainer}>{content}</div>
}

const MenuItem = ({ content, icon }) => {
  const [show, setShow] = useState(false)
  const ref = useRef(null)

  const handleClick = () => {
    setShow(!show)
  }

  const handleClose = () => {
    setShow(false)
  }

  const actionMenuItem = content.find((item) => item.type === 'action_menu')
  const regularMenuItems = content.filter((item) => item.type !== 'action_menu')

  return (
    <div className={styles.menuItem} ref={ref} onClick={handleClick} data-testid="e2e-menu-item">
      <img src={icon} height={24} />
      {content && content.length > 0 && (
        <Modal
          show={show}
          onHide={handleClose}
          aria-labelledby="contained-modal-title-vcenter"
          centered
          size="sm"
          className="p-5"
          enforceFocus={false}
        >
          <div className={styles.contentWrapper}>
            {actionMenuItem && <MoreOptions {...actionMenuItem.props} />}
            {regularMenuItems.length > 0 && (
              <div className={styles.menu}>
                {regularMenuItems.map((item, index) => (
                  <ContentItem key={index} item={item} />
                ))}
              </div>
            )}
          </div>
        </Modal>
      )}
    </div>
  )
}

export default MenuItem
