import {
  FILE_TYPE_HTML,
  FILE_TYPE_PDF,
  FILE_TYPE_ZIP,
  HTML_FILE,
  PDF_FILE,
  ZIP_FILE,
  PHP_FILE,
  EXCEL_FILE,
  IMAGE_FILE,
  DOC_FILE,
  PPT_FILE,
  FILE_TYPE_PHP,
  FILE_TYPE_EXCEL,
  FILE_TYPE_IMAGE,
  FILE_TYPE_DOC,
  FILE_TYPE_PPT
} from 'components/CreateUpdateSiteModal/interface'
import JSZip from 'jszip'
import { isHtml } from './general'
const metadataFilesPath = ['__MACOSX', '.DS_Store', 'Thumbs.db', '/._', '.git']

export const imageExtensions = [
  'jpg',
  'jpeg',
  'jpe',
  'jpf',
  'iff',
  'jp2',
  'bmp',
  'bmp2',
  'bmp3',
  'pfm',
  'gif',
  'png',
  'pn8',
  'png24',
  'png32',
  'webp',
  'tif',
  'tiff',
  'svg',
  'svgz',
  'msvgz',
  'psd',
  'xcf',
  'eps',
  'eps2',
  'eps3',
  'epsf',
  'ai',
  'raw',
  'cr2',
  'nef',
  'orf',
  'sr2',
  'crw',
  'dcr',
  'kdc',
  'arw',
  'ptif',
  'pef',
  'raf',
  'x3f',
  'erf',
  'mrw',
  'pct',
  'pict',
  'dds',
  'dng',
  'pex',
  '3fr',
  'ppm',
  'exr',
  'hdr',
  'psb',
  'pbm',
  'pgm',
  'pnm',
  'pam',
  'sgi',
  'xpm',
  'xbm',
  'xwd',
  'pcx',
  'pcc',
  'cur',
  'k25',
  'srf',
  'sct',
  'tga',
  'fit',
  'fits',
  'fts',
  'mng',
  'aai',
  'dcm',
  'dicom'
]

export const excelExtensions = ['xls', 'xlsx', 'xlsm', 'xlsb', 'xltx', 'xltm', 'xlt', 'csv', 'tsv', 'ods', 'ots']

export const docExtensions = [
  'doc',
  'docx',
  'docm',
  'dotx',
  'dotm',
  'dot',
  'rtf',
  'odt',
  'ott',
  'pages',
  'wpd',
  'wps',
  'jtd',
  'jtt',
  'tex',
  'mml',
  'sdw',
  'sxw'
]

export const ebookExtensions = ['djvu', 'epub', 'mobi', 'azw', 'azw3', 'fb2', 'iba', 'cbr', 'cbz']

export const pptExtensions = [
  'ppt',
  'pptx',
  'pptm',
  'potx',
  'potm',
  'pot',
  'ppsx',
  'ppsm',
  'pps',
  'ppam',
  'pot',
  'odp',
  'otp',
  'sdd',
  'sti',
  'sx',
  'uop',
  'sxi'
]

export const imageMimeTypes = [
  'image/jpeg',
  'image/jp2',
  'image/iff',
  'image/bmp',
  'image/x-portable-float-map',
  'image/gif',
  'image/png',
  'image/webp',
  'image/tiff',
  'image/svg+xml',
  'image/vnd.adobe.photoshop',
  'image/x-xcf',
  'application/postscript',
  'application/illustrator',
  'image/x-dcraw',
  'image/x-portable-pixmap',
  'image/x-exr',
  'image/vnd.radiance',
  'image/x-portable-bitmap',
  'image/x-portable-graymap',
  'image/x-portable-anymap',
  'image/x-portable-arbitrarymap',
  'image/x-sgi',
  'image/x-xpixmap',
  'image/x-xbitmap',
  'image/x-xwindowdump',
  'image/x-pcx',
  'image/x-k25',
  'image/x-srf',
  'image/x-scitex',
  'image/tga',
  'image/x-fit',
  'image/mng',
  'bitmap',
  'image/x-applix-graphics',
  'application/dicom'
]

export const excelMimeTypes = [
  'application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/vnd.ms-excel.sheet.macroEnabled.12',
  'application/vnd.ms-excel.sheet.binary.macroEnabled.12',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.template',
  'application/vnd.ms-excel.template.macroEnabled.12',
  'text/csv',
  'text/tab-separated-values',
  'application/vnd.oasis.opendocument.spreadsheet',
  'application/vnd.oasis.opendocument.spreadsheet-template'
]

export const docMimeTypes = [
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/vnd.ms-word.document.macroEnabled.12',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.template',
  'application/vnd.ms-word.template.macroEnabled.12',
  'application/rtf',
  'application/vnd.oasis.opendocument.text',
  'application/vnd.oasis.opendocument.text-template',
  'application/vnd.apple.pages',
  'application/vnd.wordperfect',
  'application/vnd.ms-works',
  'application/x-jtd',
  'application/x-jtt',
  'application/x-tex',
  'application/mathml+xml',
  'application/vnd.stardivision.writer',
  'application/vnd.sun.xml.writer'
]

export const ebookMimeTypes = [
  'image/vnd.djvu',
  'application/epub+zip',
  'application/x-mobipocket-ebook',
  'application/vnd.amazon.ebook',
  'application/x-fictionbook+xml',
  'application/x-ibooks+zip',
  'application/x-cbr',
  'application/x-cbz'
]

export const pptMimeTypes = [
  'application/vnd.ms-powerpoint',
  'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  'application/vnd.ms-powerpoint.presentation.macroEnabled.12',
  'application/vnd.openxmlformats-officedocument.presentationml.template',
  'application/vnd.ms-powerpoint.template.macroEnabled.12',
  'application/vnd.ms-powerpoint.template',
  'application/vnd.openxmlformats-officedocument.presentationml.slideshow',
  'application/vnd.ms-powerpoint.slideshow.macroEnabled.12',
  'application/vnd.ms-powerpoint.slideshow',
  'application/vnd.ms-powerpoint.addin.macroEnabled.12',
  'application/vnd.oasis.opendocument.presentation',
  'application/vnd.oasis.opendocument.presentation-template',
  'application/sdd',
  'application/vnd.sun.xml.impress.template',
  'application/vnd.sun.xml.impress',
  'application/vnd.openoffice.presentation'
]

export const isImageFile = (filename, mimeType) => {
  const extension = filename?.split('.')?.pop()
  return imageExtensions.includes(extension?.toLowerCase()) || (mimeType && imageMimeTypes.includes(mimeType))
}

export const isExcelFile = (filename, mimeType) => {
  const extension = filename?.split('.')?.pop()
  return excelExtensions.includes(extension?.toLowerCase()) || (mimeType && excelMimeTypes.includes(mimeType))
}

export const isDocFile = (filename, mimeType) => {
  const extension = filename?.split('.')?.pop()
  return docExtensions.includes(extension?.toLowerCase()) || (mimeType && docMimeTypes.includes(mimeType))
}

export const isPptFile = (filename, mimeType) => {
  const extension = filename?.split('.')?.pop()
  return pptExtensions.includes(extension?.toLowerCase()) || (mimeType && pptMimeTypes.includes(mimeType))
}

export const isPptPdfFile = (filename, mimeType) => {
  const extensions = filename?.split('.').slice(-3)
  for (const extension of extensions) {
    if (pptExtensions.includes(extension?.toLowerCase()) || pptMimeTypes.includes(mimeType)) return true
  }
  return false
}

export const FILE_TYPES = {
  image: 'image',
  excel: 'excel',
  doc: 'doc',
  ppt: 'ppt'
}

export const getFileCategoryType = (filename, mimeType) => {
  switch (true) {
    case isImageFile(filename, mimeType):
      return FILE_TYPES.image
    case isExcelFile(filename, mimeType):
      return FILE_TYPES.excel
    case isDocFile(filename, mimeType):
      return FILE_TYPES.doc
    case isPptFile(filename, mimeType):
      return FILE_TYPES.ppt
    default:
      return null
  }
}

export const filterPossibleHtmlFilesIndex = (htmlFiles) => {
  if (htmlFiles.length === 0) {
    return []
  }

  const directories = {}
  let foundHtmlFiles = []
  let foundIndexHtmlFiles = []

  /** Sort htmlFiles (depending on folder "staging"),
   * e.g : ["folder/folder_1/index.html", "folder/folder_2/index.html" ] will convert into  -
   * {'2': ["folder/folder_1/index.html", "folder/folder_2/index.html" ]} -
   * '2' is the number of folder to reach the file */
  htmlFiles.forEach((htmlFile) => {
    const filePathLength = htmlFile.split('/').length - 1

    if (!directories[filePathLength]) {
      directories[filePathLength] = []
    }
    directories[filePathLength].push(htmlFile)
  })

  const depths = Object.keys(directories)
  let lowestDepth = depths[0]

  depths.forEach((depth) => {
    if (lowestDepth > depth) {
      lowestDepth = depth
    }
  })

  foundHtmlFiles = directories[lowestDepth]
  foundHtmlFiles.map((foundHtmlFile) => {
    const fileName = foundHtmlFile.split('/').pop()

    if (fileName === 'index.html' || fileName === 'index.htm') {
      foundIndexHtmlFiles.push(foundHtmlFile)
    }
  })

  return foundIndexHtmlFiles.length > 0 ? foundIndexHtmlFiles : foundHtmlFiles
}

export const findHtmlFilesInZipFile = async (file) => {
  let htmlFiles = []
  let allHtmlFiles = []
  let rootDirectoryFound = false

  try {
    const reader = new FileReader()
    reader.readAsArrayBuffer(file)

    await new Promise((resolve, reject) => {
      reader.onload = resolve
      reader.onerror = reject
    })

    const content = reader.result
    const zip = await JSZip.loadAsync(content)

    zip.forEach((path) => {
      if (
        (path.toLowerCase().endsWith('.html') || path.toLowerCase().endsWith('.htm')) &&
        !metadataFilesPath.some((metadataFilePath) => path.includes(metadataFilePath))
      ) {
        allHtmlFiles.push(path)

        const index = path.indexOf('/')

        if (index === -1) {
          rootDirectoryFound = true

          htmlFiles.push(path)
        } else {
          if (!rootDirectoryFound) {
            const secondPath = path.substring(index + 1)
            const secondIndex = secondPath.indexOf('/')

            if (secondIndex === -1) {
              htmlFiles.push(path.substring(index + 1))
            }
          }
        }
      }
    })

    if (htmlFiles.length === 0 && allHtmlFiles.length !== 0) {
      const possibleHtmlIndexFiles = filterPossibleHtmlFilesIndex(allHtmlFiles)
      htmlFiles.push(...possibleHtmlIndexFiles)
    }
  } catch (error) {
    console.error(error)
  }

  return htmlFiles
}

export const getZipRootDirectory = (unzippedFiles) => {
  const index = unzippedFiles[0].path.indexOf('/')

  if (unzippedFiles[0].type === 'Directory' && unzippedFiles[0].path.substring(index + 1) === '') {
    return unzippedFiles[0].path
  }
  return ''
}

export const isIndexFile = (htmlFiles) => {
  let indexFound = 0

  if (htmlFiles.length > 1) {
    htmlFiles.map((htmlFile) => {
      const fileName = htmlFile.split('/').pop()

      if (fileName === 'index.html' || fileName === 'index.htm') {
        indexFound++
      }
    })

    return indexFound === 1 ? true : false
  }

  return true
}

export const getFileType = (fileName) => {
  const fileTypeMap = {
    '.pdf': PDF_FILE,
    '.zip': ZIP_FILE,
    '.html': HTML_FILE,
    '.php': PHP_FILE
  }

  if (!fileName) return null

  const extension = Object.keys(fileTypeMap).find((ext) => fileName.endsWith(ext))

  if (isImageFile(fileName)) {
    return IMAGE_FILE
  } else if (isExcelFile(fileName)) {
    return EXCEL_FILE
  } else if (isDocFile(fileName)) {
    return DOC_FILE
  } else if (isPptFile(fileName)) {
    return PPT_FILE
  } else {
    return fileTypeMap[extension] || null
  }
}

export const getPhpFiles = async (zipFile) => {
  const zip = new JSZip()
  const phpFiles = []
  const htmlFiles = []
  const metadataFilesPath = ['__MACOSX', '.DS_Store', 'Thumbs.db', '/._']

  try {
    const zipData = await zip.loadAsync(zipFile)

    zipData.forEach((relativePath, zipEntry) => {
      if (
        relativePath.endsWith('.php') &&
        !metadataFilesPath.some((metadataPath) => relativePath.includes(metadataPath))
      ) {
        phpFiles.push(relativePath)
      }
      if (relativePath.endsWith('.html') || relativePath.endsWith('.htm')) {
        htmlFiles.push(relativePath)
      }
    })

    const htmlFilesInSameDirectoryWithPhp = []

    // Create a set of directories containing PHP files
    const directoriesWithPhpFiles = new Set()
    phpFiles.forEach((file) => {
      const directory = file.substring(0, file.lastIndexOf('/'))
      directoriesWithPhpFiles.add(directory)
    })

    // Collect HTML files that are in the same directories as PHP files
    htmlFiles.forEach((file) => {
      const directory = file.substring(0, file.lastIndexOf('/'))
      if (directoriesWithPhpFiles.has(directory)) {
        htmlFilesInSameDirectoryWithPhp.push(file)
      }
    })

    // Combine PHP files and HTML files in the same directories
    const combinedFiles = [...phpFiles, ...htmlFilesInSameDirectoryWithPhp]

    return {
      containsPhpFile: phpFiles.length > 0,
      phpFiles: combinedFiles
    }
  } catch (error) {
    console.error('Error loading zip file:', error)
    return {
      containsPhpFile: false,
      phpFiles: []
    }
  }
}

export const getFileContentType = (fileName) => {
  if (fileName?.endsWith('.pdf')) {
    return FILE_TYPE_PDF
  } else if (fileName?.endsWith('.zip')) {
    return FILE_TYPE_ZIP
  } else if (fileName?.endsWith('.html')) {
    return FILE_TYPE_HTML
  } else if (fileName?.endsWith('php')) {
    return FILE_TYPE_PHP
  } else if (isImageFile(fileName)) {
    return FILE_TYPE_IMAGE
  } else if (isExcelFile(fileName)) {
    return FILE_TYPE_EXCEL
  } else if (isDocFile(fileName)) {
    return FILE_TYPE_DOC
  } else if (isPptFile(fileName)) {
    return FILE_TYPE_PPT
  } else {
    return null
  }
}

export const isHtmlFileExisting = (files) => {
  let isHtmlFileFound = false
  files.map((file) => {
    if (getFileType(file?.name) === HTML_FILE) {
      isHtmlFileFound = true
    }
  })
  return isHtmlFileFound
}

export const isIndexFileExisting = (files) => {
  if (isHtmlFileExisting(files)) {
    let isIndexFileFound = false

    files.map((file) => {
      if (file?.name === 'index.html') {
        isIndexFileFound = true
      }
    })
    return isIndexFileFound
  }

  return true
}

export const sortAllHtmlFiles = (files) => {
  return files.map((file) => {
    if (isHtml(file)) {
      return file.path
    }
  })
}

export const getSafeFileName = (fileName) => {
  if (fileName) {
    const index = fileName.lastIndexOf('.')
    const name = fileName.substring(0, index)
    const ext = fileName.substring(index + 1)

    return `${name.replace(/[-\s.]+/g, '-').replace(/[?>~!@%()"{}&#!<>`+]+/g, '')}.${ext}`
  }
}

export const getTrimmedZipFileName = (fileName) => {
  const index = fileName.lastIndexOf('.')
  const name = fileName.substring(0, index)

  return name.indexOf(' ') === -1 ? name : `${name.substring(0, name.indexOf(' '))}`
}
