import React from 'react'
import styles from '../../Landing/FAQ/FAQ.module.css'
import FAQS from '../../Landing/FAQ/FAQ.string'
import Accordion from 'react-bootstrap/Accordion'

const FAQ = ({ faqs = FAQS }) => (
  <div id="faq" className="mt-5">
    <div>
      <h2 className="bold">FAQs</h2>
      <Accordion className={styles.accordion} flush>
        {faqs.map(({ q, a }, i) => (
          <Accordion.Item key={i} eventKey={i}>
            <Accordion.Header className="font-18">{q}</Accordion.Header>
            <Accordion.Body className="text-start font-18 ms-3 me-3">
              <span dangerouslySetInnerHTML={{ __html: a }} />
            </Accordion.Body>
          </Accordion.Item>
        ))}
      </Accordion>
    </div>
  </div>
)

export default FAQ
