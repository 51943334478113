export const DEEP_LINK = {
  MANAGE_ACCOUNT: '/manage/account',
  CREATE_SITE: '/manage/upload',
  PASTE_HTML: '/manage/upload/paste-html',
  CANCELLATION: '/manage/subscription/cancel',
  UPDATE_SITE: '/manage/update', // e.g. /manage/update?site=abc.tiiny.site
  PURCHASE_CUSTOM_DOMAIN: '/manage/custom-domain/buy',
  ADD_CUSTOM_DOMAIN: '/manage/custom-domain/add',
  SHARE: '/manage/share', // e.g. /manage/share?site=abc.tiiny.site
  EMBED: '/manage/share/embed', // e.g. /manage/share/embed?site=abc.tiiny.site
  REACTIVATE_SUB: '/manage/subscription/reactivate',

  WRITE_TEXT: '/manage/upload/text', // TODO: Click next, flow doesn't work
  MANAGE_ANALYTICS: '/manage/analytics', // TODO: Doesn't open modal on free
  CHANGE_PLAN: '/manage/subscription/change', // TODO: Doesn't work
  MANAGE_TEAM: '/manage/team', // TODO: Should open paywall on free plan
  QR: '/manage/share/qr', // TODO: Doesn't show paywall on free
  UPGRADE_PLAN: '/manage/subscription/upgrade' // TODO: No modal opens
}

export default DEEP_LINK
