import { isDevOrPreviewSite } from '../../utils/general'

export const API_PLAN_PRICES = {
  yearly: {
    EU: {
      stripePriceId: isDevOrPreviewSite() ? '' : '',
      currency: '€',
      value: 888
    },
    UK: {
      stripePriceId: isDevOrPreviewSite() ? '' : '',
      currency: '£',
      value: 696
    },
    US: {
      stripePriceId: isDevOrPreviewSite() ? 'price_1QL6WuKnX1nnNv6IgkSO9BbK' : 'price_1QMaluKnX1nnNv6INKfC4hvP',
      currency: '$',
      value: 888
    },
    INDR: {
      stripePriceId: isDevOrPreviewSite() ? '' : '',
      currency: '₹',
      value: 45000
    }
  },
  monthly: {
    EU: {
      stripePriceId: isDevOrPreviewSite() ? '' : '',
      currency: '€',
      value: 99
    },
    UK: {
      stripePriceId: isDevOrPreviewSite() ? '' : '',
      currency: '£',
      value: 78
    },
    US: {
      stripePriceId: isDevOrPreviewSite() ? 'price_1QL6VkKnX1nnNv6I2Hu2tpbS' : 'price_1QMaluKnX1nnNv6IRARwQksc',
      currency: '$',
      value: 99
    },
    INDR: {
      stripePriceId: isDevOrPreviewSite() ? '' : '',
      currency: '₹',
      value: 5000
    }
  }
}
