import { isDevOrPreviewSite } from '../../utils/general'
import { F_API_KEY, F_CUSTOM_DOMAIN, F_EDIT_MODE, F_EMAIL_GATE, F_PWD_PROTECTION } from './constants'

const PRODUCT_ID_PROD = 'prod_RF4v0SrJzdH7IS'
const PRODUCT_ID_DEV = 'prod_RDXa0qtUpaMAcL'

const PRICE_ID_YEARLY_PROD = 'price_1QMaluKnX1nnNv6INKfC4hvP'
const PRICE_ID_YEARLY_DEV = 'price_1QL6WuKnX1nnNv6IgkSO9BbK'

const PRICE_ID_MONTHLY_PROD = 'price_1QMaluKnX1nnNv6IRARwQksc'
const PRICE_ID_MONTHLY_DEV = 'price_1QL6VkKnX1nnNv6I2Hu2tpbS'

const PADDLE_PRICE_ID_YEARLY_PROD = 0
const PADDLE_PRICE_ID_YEARLY_DEV = 0

const PADDLE_PRICE_ID_MONTHLY_PROD = 0
const PADDLE_PRICE_ID_MONTHLY_DEV = 0

const PADDLE_PRICE_ID_YEARLY = isDevOrPreviewSite() ? PADDLE_PRICE_ID_YEARLY_DEV : PADDLE_PRICE_ID_YEARLY_PROD
const PADDLE_PRICE_ID_MONTHLY = isDevOrPreviewSite() ? PADDLE_PRICE_ID_MONTHLY_DEV : PADDLE_PRICE_ID_MONTHLY_PROD
const PRODUCT_ID = isDevOrPreviewSite() ? PRODUCT_ID_DEV : PRODUCT_ID_PROD
const PRICE_ID_YEARLY = isDevOrPreviewSite() ? PRICE_ID_YEARLY_DEV : PRICE_ID_YEARLY_PROD
const PRICE_ID_MONTHLY = isDevOrPreviewSite() ? PRICE_ID_MONTHLY_DEV : PRICE_ID_MONTHLY_PROD

const API_PLAN = {
  id: PRODUCT_ID,
  label: 'API',
  prices: {
    yearly: {
      id: PRICE_ID_YEARLY,
      value: 888,
      paddleLink: 'https://buy.paddle.com/product/761274',
      paddleId: PADDLE_PRICE_ID_YEARLY
    },
    monthly: {
      id: PRICE_ID_MONTHLY,
      value: 99,
      paddleLink: 'https://buy.paddle.com/product/761272',
      paddleId: PADDLE_PRICE_ID_MONTHLY
    }
  },
  fileSizeLimit: 75,
  customDomainsLimit: 5,
  projectLimit: 999,
  accountLimit: 10000,
  visitorLimit: 500000,
  bandwidthLimit: 100,
  features: {
    [F_CUSTOM_DOMAIN]: true,
    [F_PWD_PROTECTION]: true,
    [F_EDIT_MODE]: true,
    [F_API_KEY]: true,
    [F_EMAIL_GATE]: true
  }
}

export default API_PLAN
