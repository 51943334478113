const API_FAQS = [
  {
    q: 'Do you provide documentation?',
    a: 'Yes, we do - <a href="https://tiiny.host/api-docs">https://tiiny.host/api-docs</a>'
  },
  {
    q: 'What API functions can I perform?',
    a: 'You can create, update and delete links as well as list links on your account.'
  },
  {
    q: 'Do you provide integration support?',
    a: 'Yes we do. Our APIs are very simple and designed to be self-service however if you do require assistance just reach out via our support channels.'
  },
  {
    q: 'What type of file formats do you support?',
    a: 'We currently support HTML, ZIP, PHP, PDF files, and almost all popular image, raw image, document, powerpoint and Excel file formats (more coming soon).<br /> <br />If you\'re uploading a website, we support static sites.<br /><br />A static site is composed of frontend only static files such as HTML, JavaScript and images (jpeg, png, svg etc). These files should be uploaded as a zip file.<br /><br />View our full list <a href="https://helpdesk.tiiny.host/en/article/what-type-of-file-formats-do-you-support-na0784/?bust=1730236719643">here</a>'
  },
  {
    q: 'Why do accounts take 24 hours to approve?',
    a: 'In order to avoid misuse and spam we require to manually approve all sign-ups.'
  },
  {
    q: 'How does your 7 day money back guarantee work?',
    a: "We have many happy customers. However, if for some reason after you upgrade to one of our paid plans you decide that it's not for you, then just reach out to our friendly support within 7 days and we'll be happy to give you a full refund."
  },
  {
    q: 'Can I cancel at anytime?',
    a: 'Yes, if you no longer need us, just cancel your subscription via your control panel and your account will be terminated at the end of your billing period.'
  },
  {
    q: 'What happens after I cancel?',
    a: "Your sites will remain live until the end of your billing period. After this time, we'll completely remove them along with your account from our servers."
  }
]

export default API_FAQS
