import React, { useEffect, useState } from 'react'
import { Button, Modal } from 'react-bootstrap'
import { useHistory } from 'react-router-dom'

import Add from './components/Add'

export const STAGE_EDIT = 0
export const STAGE_SUCCESS = 1
export const STAGE_FAILED = 2
export const STAGE_SUCCESS_MANUAL = 3

const Success = ({ onHide, msg }) => (
  <>
    <div className="p-4 text-center">
      <img className="me-2" height={20} width={20} src="/assets/icons/tick.png" /> {msg}
    </div>
    <Button className="action-btn round-top-0" type="submit" variant="primary" onClick={onHide}>
      DONE
    </Button>
  </>
)

const AddTeamMembersModal = ({ show, currentTeamMembers, onHide }) => {
  const [stage, setStage] = useState(STAGE_EDIT)
  const history = useHistory()

  useEffect(() => {
    setStage(STAGE_EDIT)
  }, [show])

  useEffect(() => {
    if (show) {
      history.push({ pathname: '/manage/team' })
    }
  }, [show, history])

  const onClose = () => {
    history.push({ pathname: '/manage' })
    onHide()
  }

  return (
    <Modal aria-labelledby="contained-modal-title-vcenter" show={show} onHide={onClose} enforceFocus={false} centered>
      {stage === STAGE_EDIT && (
        <Add
          existingTeamMembers={currentTeamMembers}
          onHide={onHide}
          setStage={setStage}
          teamMemberLimit={currentTeamMembers.length > 2 ? currentTeamMembers.length : 2}
        />
      )}
      {stage === STAGE_SUCCESS && <Success onHide={onHide} msg="Your account has been successfully updated" />}
      {stage === STAGE_SUCCESS_MANUAL && (
        <Success onHide={onHide} msg="We've received your request and will update your account shortly" />
      )}
    </Modal>
  )
}

export default AddTeamMembersModal
