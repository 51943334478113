import React from 'react'
import { Button } from 'react-bootstrap'

import styles from './APIPlanCard.module.css'

const APIPlanCard = ({ onClick }) => {
  return (
    <div className={styles.content} id="signup">
      <div className={styles.contentImage}></div>
      <div className={styles.contentInfo}>
        <div className="w-100 text-start">
          <h3 className={styles.title}>Sign up for API access</h3>
          <div className="grey">Perfect for building automations and connecting apps</div>

          <div className="flex">
            <span className="font-26 font-weight-900  bold">
              $<b className="font-47 bold">99</b>
              <span className={`${styles.period} black ms-2`}>/ month</span>
            </span>
          </div>

          <div className="flex mt-2">
            <img className="mt-1" height="18px" width="18px" alt="check" src="/assets/icons/tick.png" />
            <div className="ms-2">Unlimited links & API calls</div>
          </div>

          <div className="flex mt-2">
            <img className="mt-1" height="18px" width="18px" alt="check" src="/assets/icons/tick.png" />
            <div className="ms-2">Upload up to 75 mb files</div>
          </div>

          <div className="flex mt-2">
            <img className="mt-1" height="18px" width="18px" alt="check" src="/assets/icons/tick.png" />
            <div className="ms-2">Upgradeable 5 GB account limit</div>
          </div>

          <div className="flex mt-2">
            <img className="mt-1" height="18px" width="18px" alt="check" src="/assets/icons/tick.png" />
            <div className="ms-2">Pro plan account</div>
          </div>

          <div className="flex mt-2">
            <img className="mt-1" height="18px" width="18px" alt="check" src="/assets/icons/tick.png" />
            <div className="ms-2">Dedicated technical support</div>
          </div>

          <div className="guarantee mt-4 font-16 grey bg-light-grey p-2 text-start">🎖️ 7-day money back guarantee</div>

          <Button className="mt-4 w-100" size="lg" onClick={onClick}>
            Sign Up
          </Button>
        </div>
      </div>
    </div>
  )
}

export default APIPlanCard
