import React, { useEffect } from 'react'
import Footer from '../components/Footer'
import Home from './Home/Home'
import HomeHeader from '../components/HomePageHeader'
import { withRouter } from 'react-router'
import ProPlans from '../components/ProPlans'
import UpgradeModal from '../components/UpgradePromoModal'
import UseCase from './UseCases/UseCase'
import ScaleCounter from './ScaleCounter/ScaleCounter'
import FAQ from './FAQ/FAQ'
import HowItWorks from './HowItWorks/HowItWorks'
import { SOURCE_HOMEPAGE } from '../constants'
import DropzoneWrapper from 'components/DragAndDrop/component/DropzoneWrapper'
import ReviewCard from '../components/ReviewCard/ReviewCard'
import Features from '../components/FeaturesBox/FeaturesBox'
import CTAButton from '../components/CTAButton/CTAButton'
import FeatureGrid from './FeatureGrid/FeatureGrid'
import { DROPZONE_ACCEPTED_FILES } from '../constants'

import '../App.css'

const Landing = ({ history }) => {
  useEffect(() => {
    const token = localStorage.getItem('token')
  
    if (token) {
      // Redirect to /manage with the current URL parameters
      history.push('/manage' + history.location.search);
    }
  }, []);

  return (
    <DropzoneWrapper acceptedFiles={DROPZONE_ACCEPTED_FILES}>
      <div className="body-bg full-height">
        <UpgradeModal onClick={() => history.push('/pricing')} trialAccount={true} />
        <HomeHeader />
        <Home />
        <HowItWorks />
        <ReviewCard />
        <UseCase history={history} />
        <ScaleCounter />
        <Features />
        <FeatureGrid />
        <div className="text-center">
          <ProPlans
            className="container mb-5 mt-v8 pe-5 ps-5"
            onFreeClick={() => window.scrollTo(0, 0)}
            source={SOURCE_HOMEPAGE}
            hideTable
          />
          <CTAButton
            className="me-auto ms-auto mt--3"
            label="Compare plans"
            variant="secondary-round"
            size="large"
            onClick={() => history.push('/pricing')}
          />
        </div>
        <FAQ />
        <Footer />
      </div>
    </DropzoneWrapper>
  )
}

export default withRouter(Landing)
