import React, { lazy, Suspense } from 'react'
import { Spinner, Tab, Tabs } from 'react-bootstrap'
import { withRouter } from 'react-router'
import { connect } from 'react-redux'
import download from 'downloadjs'
import 'pure-react-carousel/dist/react-carousel.es.css'
import JSZip from 'jszip'
import { API_ROOT } from '../constants'
import ConfirmationModal from '../components/ConfirmationModal'
import {
  toggleModal,
  accountModal,
  actionProcessing,
  fetchUserData,
  hideMessageModal,
  showCreateSiteModal,
  showMessageModal,
  showUpgradeModal as showSubscribeModal,
  toggleTeamModal,
  setFirstTimeSuccModal,
  setQrCodeModal,
  setQrCodeData,
  setDraggedFile,
  setPreviewModal,
  setPreviewData,
  setEmbedData,
  setSiteType,
  showCustomDomainModal
} from './actions'
import HomeHeader from './components/Header/Header'
import CustomDomain from '../components/CustomDomainTable'
import ManageAccountModal from '../components/ManageAccountModal'
import { hasFeature } from '../utils/general'
import MessageModal from '../components/MessageModal'
import api from '../utils/api'
import ArchiveTable from '../components/ArchiveTable/ArchiveTable'
import FileListModal from '../components/FileListModal/FileListModal'
import AnalyticsModal from '../components/AnalyticsModal/AnalyticsModal'
import EmbedModal from '../components/EmbedModal/EmbedModal'
import { downloadSite } from '../services/manage'
import LiveSitesTable from '../components/LiveSitesTable'
import UpgradePromoCard from '../components/UpgradePromoModal'
import { showUpgradeCardModal, setShowPaymentModal, UPGRADE_QR_PAYLOAD } from '../actions'
import ProPlansModal from '../components/ProPlans/ProPlansModal'
import { PLANS, PLANS_ID } from '../constants/plans'
import { trackUpload } from '../utils/analytics'
import AddTeamMembersModal from './components/AddTeamMembersModal/AddTeamMembersModal'
import DragWrapper from 'components/DragAndDrop/DragWrapper'
import QRCodeModal from 'components/QRCodeModal/QRCodeModal'
import PDFEditorModal from '../components/PDFEditor/PDFEditor'
import FirstTimeSucessModal from 'components/SuccessBox/FirstTimeSuccessModal'
import { F_CUSTOM_DOMAIN, F_EDIT_MODE } from '../constants/plans/constants'
import BandwidthAlert from './components/BandwidthAlert/BandwidthAlert'
import { getAddToCalHTML } from '../utils/AddToCal'
import PreviewModal from 'components/PreviewModal/PreviewModal'
import CreateUpdateSiteModal from 'components/CreateUpdateSiteModal'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'
import { ACTION_CREATE, ACTION_UPDATE } from '../components/CreateUpdateSiteModal/interface'
import ErrorBoundary from 'components/ErrorBoundary/ErrorBoundary'
import { ErrorBox } from 'components/ErrorBox/ErrorBox'
import Refund from 'Refund'
import DiscountBanner from './components/DiscountBanner/DiscountBanner'
import ShareModal from 'components/ShareModal/ShareModal'
import { openPricingPopup, refreshLogin, uploadedFileTooBig } from 'utils/sendy'
import UserFeedbackModal from '../components/InsightPipeline/UserFeedbackModal'
import PlanPaymentModal from 'components/PlanPaymentModal/PlanPaymentModal'
import DEEP_LINK from '../utils/deepLinks'
import { withGoogleReCaptcha } from 'react-google-recaptcha-v3'
import ClaimSiteModal from './components/ClaimSiteModal/ClaimSiteModal'

const TextEditor = lazy(() => import('../components/TextEditor/TextEditor.js'))
const CodeEditor = lazy(() => import('../components/CodeEditor/CodeEditor'))

import './Manage.css'
import APIWelcomeModal from '../components/APIWelcomeModal/APIWelcomeModal'

const ACTION_ARCHIVE = 'ARCHIVE'
const ACTION_DELETE = 'DELETE'
const ACTION_DELETE_ARCHIVE = 'ARCHIVE/DELETE'
const ACTION_DELETE_ACCOUNT = 'DELETE/ACCOUNT'
const ACTION_DOWNLOAD = 'DOWNLOAD'

class Manage extends React.Component {
  state = {
    isLoading: true
  }

  componentDidMount() {
    if (localStorage.getItem('token')) {
      this.refreshProfile()
      refreshLogin()

      const urlParams = new URLSearchParams(window.location.search)
      const gptDomain = urlParams.get('gpt-domain')

      if (gptDomain) {
        // Set the site state based on gptDomain
        this.setState({
          showClaimSiteModal: true // Show the ClaimSiteModal
        })
      }
    } else {
      localStorage.setItem('redirect-url', window.location.href)
      window.location = '/login'
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps?.userProfile?.email !== this.props?.userProfile?.email) {
      const { email, productId } = this.props.userProfile
      const plan = PLANS_ID[productId]?.label || 'Free'
      if (window.$crisp) {
        window.$crisp.push([
          'set',
          'session:data',
          [
            'profile',
            `https://tiiny.retool.com/apps/7b6dcc62-5e11-11ef-85ab-4710317d137f/User%20Profile?email=${email}`
          ]
        ])
        window.$crisp.push(['set', 'session:segments', [[plan]]])
        window.$crisp.push(['set', 'user:email', email])
      }

      const urlParams = new URLSearchParams(window.location.search)

      // track only if first
      const first = urlParams.get('first')
      if (first === 'true') {
        if (this.props.liveSites.length === 1) {
          const site = this.props.liveSites[0]

          trackUpload(plan, site?.size, site?.type, email)

          if (site?.linkPreview) {
            uploadedFileTooBig()
            this.props.dispatch(setPreviewModal(true))
            this.props.dispatch(setPreviewData(site))
          } else {
            this.props.dispatch(setFirstTimeSuccModal(true))
          }
        }
        urlParams.delete('first')
        this.props.history.replace({
          pathname: '/manage',
          search: urlParams.toString()
        })
      }

      const ref = urlParams.get('ref')
      if (ref === 'invite') {
        const addToCalConfig = localStorage.getItem('addToCalConfig')
        localStorage.removeItem('addToCalConfig')
        const htmlContent = getAddToCalHTML(JSON.parse(addToCalConfig))
        const inviteFile = new File([htmlContent], 'invite.html', {
          type: 'text/html',
          lastModified: new Date(),
          path: 'invite.html'
        })
        this.props.dispatch(setDraggedFile(inviteFile))
        this.onSiteAction(ACTION_CREATE)
      } else if (ref === 'test-html') {
        const retrievedHTML = localStorage.getItem('html-code')
        const retrievedCSS = localStorage.getItem('css-code')
        const retrievedJS = localStorage.getItem('js-code')

        if (retrievedHTML) {
          const zip = new JSZip()
          zip
            .folder('html')
            .file('index.html', retrievedHTML)
            .file('main.css', retrievedCSS)
            .file('main.js', retrievedJS)

          zip
            .generateAsync({ type: 'blob' })
            .then((result) => {
              let file = new File([result], 'html.zip', {
                type: 'application/zip',
                lastModified: new Date(),
                path: 'html.zip'
              })

              // clean up
              localStorage.removeItem('html-code')
              localStorage.removeItem('css-code')
              localStorage.removeItem('js-code')

              this.props.dispatch(setDraggedFile(file))
              this.onSiteAction(ACTION_CREATE)
            })
            .catch((err) => console.error(err))
        }
      }

      this.handleDeepLinking()
    }
  }

  handleDeepLinking = () => {
    const urlParams = new URLSearchParams(window.location.search)
    const { pathname } = this.props.location
    const { dispatch, liveSites, userProfile, history } = this.props
    const trialAccount = !userProfile.productId

    const getSiteFromUrl = () => {
      const siteUrl = urlParams.get('site')
      return liveSites?.find((site) => site.subdomain === siteUrl)
    }

    const actions = {
      [DEEP_LINK.MANAGE_ANALYTICS]: () => {
        const site = getSiteFromUrl()
        if (site) {
          this.setState({ showAnalyticsModal: true, domainToAction: site.subdomain })
        } else {
          history.replace({ pathname: '/manage' })
        }
      },
      [DEEP_LINK.CHANGE_PLAN]: () => {
        if (trialAccount) {
          dispatch(showSubscribeModal(true))
          return
        }
        dispatch(
          setShowPaymentModal({
            showPaymentModal: true,
            upgradePlanId: userProfile?.productId
          })
        )
      },
      [DEEP_LINK.REACTIVATE_SUB]: () => dispatch(accountModal(true)),
      [DEEP_LINK.MANAGE_ACCOUNT]: () => dispatch(accountModal(true)),
      [DEEP_LINK.CREATE_SITE]: () => this.onSiteAction(ACTION_CREATE),
      [DEEP_LINK.PASTE_HTML]: () => this.setState({ showCodeEditor: true }),
      [DEEP_LINK.WRITE_TEXT]: () => this.onOpenTextEditor(),
      [DEEP_LINK.ADD_CUSTOM_DOMAIN]: () => this.onAddCustomDomain(),
      [DEEP_LINK.PURCHASE_CUSTOM_DOMAIN]: () => this.onAddCustomDomain(),
      [DEEP_LINK.UPDATE_SITE]: () => {
        const site = getSiteFromUrl()
        if (site) {
          history.replace({ pathname: '/manage/update' })
          dispatch(setDraggedFile(null))
          dispatch(toggleModal(true))
          this.onSiteAction(ACTION_UPDATE, site)
        } else {
          history.replace({ pathname: '/manage' })
        }
      },
      [DEEP_LINK.SHARE]: () => {
        const site = getSiteFromUrl()
        if (site && !trialAccount) {
          this.onShare(site)
        } else {
          history.replace({ pathname: '/manage' })
        }
      },
      [DEEP_LINK.QR]: () => {
        const site = getSiteFromUrl()
        if (site) {
          this.onQRCode(site)
        } else {
          history.replace({ pathname: '/manage' })
        }
      },
      [DEEP_LINK.EMBED]: () => {
        const site = getSiteFromUrl()
        if (site && !trialAccount) {
          this.onEmbed(site)
        } else {
          history.replace({ pathname: '/manage' })
        }
      },
      [DEEP_LINK.MANAGE_TEAM]: () => {
        if (userProfile.productId === PLANS.PRO_U.id) {
          dispatch(toggleTeamModal(true))
        } else {
          dispatch(setShowPaymentModal({ showPaymentModal: true, upgradePlanId: PLANS.PRO_U.id }))
        }
      },
      [DEEP_LINK.UPGRADE_PLAN]: () => {
        const planId = urlParams.get('planId')
        if (trialAccount) {
          dispatch(showSubscribeModal(true))
          return
        }

        if (planId) {
          dispatch(setShowPaymentModal({ showPaymentModal: true, upgradePlanId: planId }))
          return
        }

        dispatch(setShowPaymentModal({ showPaymentModal: true, upgradePlanId: userProfile?.productId }))
      },
      [DEEP_LINK.CANCELLATION]: () => dispatch(accountModal(true))
    }

    const action = actions[pathname]
    if (action) {
      action()
    }
  }

  onSiteAction = (modalAction, data) => {
    let modalData
    if (data) {
      modalData = {
        ...data,
        domain: data.subdomain
      }
    }

    this.props.dispatch(showCreateSiteModal(true, modalData, modalAction))
  }

  refreshProfile = () => {
    this.props.dispatch(fetchUserData())
  }

  onConfirmPositive = () => {
    this.props.dispatch(actionProcessing(true))
    if (this.state.modalAction === ACTION_DOWNLOAD) {
      this.downloadSiteAction(this.state.domainToAction)
      return
    }
    api
      .post(`${API_ROOT}/pro/${this.state.modalAction.toLowerCase()}`, {
        domain: this.state?.domainToAction?.subdomain || this.state?.domainToAction?.link,
        site: this.state.domainToAction
      })
      .then((res) => {
        this.refreshProfile()
        this.setState({
          modalConfirmationShow: false
        })
        this.props.dispatch(actionProcessing(false))
      })
      .catch((err) => {
        this.props.dispatch(actionProcessing(false))
        // show error toast
      })
  }

  onConfirmNegative = () => {
    this.setState({
      modalConfirmationShow: false
    })
  }

  onDelete = (site) => {
    this.setState({
      modalAction: ACTION_DELETE,
      modalConfirmationShow: true,
      domainToAction: site,
      confirmMessage: `Are you sure you want to delete ${(!site.linkPreview && site.subdomain) || 'this site'}?`
    })
  }

  onArchive = (site) => {
    if (this.props.userProfile.productId) {
      this.setState({
        modalAction: ACTION_ARCHIVE,
        modalConfirmationShow: true,
        domainToAction: site,
        confirmMessage: `Are you sure you want to archive ${site?.subdomain}?`
      })
    } else {
      this.props.dispatch(
        showUpgradeCardModal({
          title: 'Upgrade to archive your sites for later',
          desc: 'Take your site offline but store it to quickly re-launch later',
          cta: 'Upgrade to archive',
          onClick: () =>
            this.props.dispatch(setShowPaymentModal({ showPaymentModal: true, upgradePlanId: PLANS.TINY.id }))
        })
      )
    }
  }

  onEdit = (site) => {
    this.setState({
      showFileList: true,
      domainToAction: site
    })
  }

  onFileToEditSelected = (file) => {
    this.setState({
      showFileList: false,
      showCodeEditor: true,
      fileToEdit: file
    })
  }

  onEmbed = (site) => {
    const { dispatch } = this.props
    dispatch(setEmbedData(true, site))
  }

  onDeleteArchive = (site) => {
    this.setState({
      modalAction: ACTION_DELETE_ARCHIVE,
      modalConfirmationShow: true,
      domainToAction: site,
      confirmMessage: `Are you sure you want to delete archive ${site?.subdomain}?`
    })
  }

  downloadSiteAction = (site) => {
    downloadSite({ domain: site.subdomain }, { responseType: 'arraybuffer' })
      .then((response) => {
        const contentDisposition = response.headers['content-disposition']
        let filename = response.headers['x-filename']

        if (contentDisposition) {
          const match = contentDisposition.match(/filename="?([^"]+)"?/)
          if (match && match[1]) {
            filename = match[1] // Extract filename from header if available
          }
        }

        // Initiate download with the provided filename and content type
        const contentType = response.headers['content-type'] || 'application/octet-stream'
        download(response.data, filename, contentType)
        this.setState({
          modalConfirmationShow: false
        })
        this.props.dispatch(actionProcessing(false))
      })
      .catch(() => {
        this.props.dispatch(
          showMessageModal({
            title: 'Download failed',
            message: 'Unable to download site, please contact support.',
            error: true
          })
        )
      })
  }

  onDownload = (site) => {
    this.setState({
      modalAction: ACTION_DOWNLOAD, // Set the action to DOWNLOAD
      modalConfirmationShow: true, // Show the confirmation modal
      domainToAction: site, // Store the site to be downloaded
      confirmMessage: `Are you sure you want to download ${site.subdomain}?` // Set the confirmation message
    })
  }

  showUpgradeView = (trial) => {
    if (trial) {
      this.props.dispatch(showSubscribeModal(true))
    } else {
      this.setState({ showUpgradeModal: true })
    }
    openPricingPopup()
  }

  onAnalytics = (site) => {
    this.setState({
      showAnalyticsModal: true,
      domainToAction: site
    })
  }

  onEditPDF = (fileLink) => {
    this.setState({
      showPDFEditor: true,
      pdfUrlToEdit: fileLink
    })
  }

  onOpenTextEditor = (site) => {
    this.setState({
      showTextEditor: true,
      siteToAction: site
    })
  }

  onQRCode = (site) => {
    const { dispatch, userProfile } = this.props

    if (userProfile?.productId) {
      dispatch(setQrCodeModal(true))
      dispatch(setQrCodeData(site))
    } else {
      dispatch(
        showUpgradeCardModal({
          ...UPGRADE_QR_PAYLOAD,
          onClick: () => dispatch(setShowPaymentModal({ showPaymentModal: true, upgradePlanId: PLANS.TINY.id }))
        })
      )
    }
  }

  onPreview = (site) => {
    const { dispatch } = this.props
    uploadedFileTooBig()
    dispatch(setPreviewModal(true))
    dispatch(setPreviewData(site))
  }

  onDeleteAccount = () => {
    this.setState({
      modalAction: ACTION_DELETE_ACCOUNT,
      modalConfirmationShow: true,
      confirmMessage: `Are you sure you want to delete your account & links?`
    })
  }

  onShare = (site) => {
    this.setState({
      domainToAction: site,
      showShareModal: true
    })
  }

  onAddCustomDomain = () => {
    if (!hasFeature(this.props.userProfile?.productId, F_CUSTOM_DOMAIN)) {
      this.props.dispatch(
        showUpgradeCardModal({
          title: 'Upgrade to connect your own website domain',
          desc: 'Use your own brand and upload content to your own website domain',
          cta: 'Connect domain',
          onClick: () =>
            this.props.dispatch(setShowPaymentModal({ showPaymentModal: true, upgradePlanId: PLANS.PRO.id }))
        })
      )
    } else if (this.props.userProfile?.siteLimit === this.props.userProfile?.customDomains?.length) {
      this.props.dispatch(showMessageModal({ title: 'Limit reached', message: 'Custom domain limit reached' }))
    } else {
      this.props.dispatch(showCustomDomainModal(true))
    }
  }

  onCloseClaimSiteModal = () => {
    this.setState({ showClaimSiteModal: false })
  }

  render() {
    const {
      fileToEdit,
      modalConfirmationShow,
      confirmMessage,
      domainToAction,
      showFileList,
      showCodeEditor,
      showAnalyticsModal,
      showUpgradeModal,
      showTextEditor,
      showShareModal,
      siteToAction,
      showClaimSiteModal,
      site
    } = this.state

    const {
      dispatch,
      accountModalVisible,
      liveSites = [],
      modalMessageData,
      showMessageModal,
      archivedSites,
      userProfile = {},
      actionProcessing,
      fetchingUser,
      customDomains,
      showTeamModal,
      members,
      history,
      showFistTimeSuccModal,
      showQRCodeModal,
      QRCodeData,
      showPreviewModal,
      PreviewData,
      embedData,
      showPaymentModal,
      upgradePlanId,
      googleReCaptchaProps
    } = this.props

    const trialAccount = !userProfile.productId
    const siteLimit = trialAccount ? 1 : userProfile.siteLimit || 5
    const liveSitesCount = liveSites.length
    const showResumeBanner = localStorage?.getItem('landing-page') === 'resume'
    const fallbackComponent = (errorProps) => (
      <ErrorBox className="d-block" message={'Unable to load view<br /> please contact support'} hideButton={true} />
    )

    return (
      <DragWrapper trialAccount={trialAccount} siteLimit={siteLimit} liveSites={liveSites}>
        <div className="full-height pb-5">
          <FirstTimeSucessModal
            show={showFistTimeSuccModal}
            onHide={() => dispatch(setFirstTimeSuccModal(false))}
            domain={liveSites[0]}
            userProfile={userProfile}
          />
          {userProfile?.productId === PLANS.API.id && <APIWelcomeModal apiKey={userProfile?.apiKey} />}
          <ManageAccountModal
            show={accountModalVisible}
            onHide={() => dispatch(accountModal(false))}
            onUpgrade={() =>
              dispatch(setShowPaymentModal({ showPaymentModal: true, upgradePlanId: userProfile?.productId }))
            }
          />
          <ShareModal
            show={showShareModal}
            onClose={() => {
              this.setState({ showShareModal: false })
            }}
            userProfile={userProfile}
            site={domainToAction}
            files={[]}
            onReset={() => {}}
          />
          <CreateUpdateSiteModal
            sites={liveSites}
            disableProFeatures={trialAccount}
            liveSitesCount={liveSitesCount}
            executeRecaptcha={googleReCaptchaProps?.executeRecaptcha}
          />
          <ConfirmationModal
            title="Delete site"
            message={confirmMessage}
            show={modalConfirmationShow}
            handlePositive={this.onConfirmPositive}
            handleNegative={this.onConfirmNegative}
            actionProcessing={actionProcessing}
          />
          <MessageModal
            show={showMessageModal}
            handleNeutral={() => dispatch(hideMessageModal())}
            {...modalMessageData}
          />
          <FileListModal
            domain={domainToAction}
            show={showFileList}
            onHide={() => this.setState({ showFileList: false })}
            onFileSelected={this.onFileToEditSelected}
          />
          <EmbedModal
            show={embedData.show}
            onHide={() => {
              dispatch(setEmbedData(false, ''))
            }}
            domain={embedData.site}
          />
          <AnalyticsModal
            data={domainToAction?.analytics}
            domain={domainToAction}
            show={showAnalyticsModal}
            trialMode={trialAccount}
            onHide={() => {
              this.setState({ showAnalyticsModal: false })
            }}
            onUpgradeCTA={() => dispatch(setShowPaymentModal({ showPaymentModal: true, upgradePlanId: PLANS.TINY.id }))}
          />
          <QRCodeModal
            show={showQRCodeModal}
            onHide={() => {
              dispatch(setQrCodeModal(false))
            }}
            domain={QRCodeData}
          />
          <PreviewModal show={showPreviewModal} onHide={() => dispatch(setPreviewModal(false))} site={PreviewData} />
          <PlanPaymentModal
            show={showPaymentModal}
            onHide={() => dispatch(setShowPaymentModal({ showPaymentModal: false, upgradePlanId: null }))}
            planId={upgradePlanId}
            history={history}
          />
          <AddTeamMembersModal
            show={showTeamModal}
            onHide={() => {
              dispatch(toggleTeamModal(false))
              this.refreshProfile()
            }}
            currentTeamMembers={members}
          />
          <PDFEditorModal
            document={this.state.pdfUrlToEdit}
            show={this.state.showPDFEditor}
            onClose={() =>
              this.setState({
                showPDFEditor: undefined,
                pdfUrlToEdit: undefined
              })
            }
            trial={!hasFeature(userProfile.productId, F_EDIT_MODE)}
            onUpgrade={() => dispatch(setShowPaymentModal({ showPaymentModal: true, upgradePlanId: PLANS.PRO.id }))}
          />
          <ClaimSiteModal
            userProfile={this.props.userProfile}
            liveSitesCount={liveSitesCount}
            onClose={this.onCloseClaimSiteModal}
            show={showClaimSiteModal}
          />
          <Suspense fallback={<div />}>
            <TextEditor
              show={this.state.showTextEditor}
              onHide={() => this.setState({ showTextEditor: false, siteToAction: false })}
              onSiteAction={(actionState, siteToAction) => this.onSiteAction(actionState, siteToAction)}
              setDraggedFile={(file) => dispatch(setDraggedFile(file))}
              setSiteType={(siteType) => dispatch(setSiteType(siteType))}
              siteToAction={this.state.siteToAction}
            />
          </Suspense>
          <Suspense fallback={<div />}>
            <CodeEditor
              show={showCodeEditor}
              onHide={() => this.setState({ showCodeEditor: false })}
              file={fileToEdit}
              domain={domainToAction}
              onSiteAction={this.onSiteAction}
              setDraggedFile={(file) => dispatch(setDraggedFile(file))}
            />
          </Suspense>
          <Refund />
          {/* <BuyMoreSitesModal
            show={this.state.showEditSites}
            siteLimit={6}
            onHide={() => this.setState({ showEditSites: false })}
          />*/}
          {!showResumeBanner && (
            <DiscountBanner
              onUpgrade={() => {
                dispatch(setShowPaymentModal({ showPaymentModal: true, upgradePlanId: PLANS.TINY.id })),
                  localStorage.setItem('discount-code', 'ILwFIMwL')
              }}
              userProfile={userProfile}
            />
          )}
          <HomeHeader
            email={userProfile.email}
            productCode={userProfile.productCode}
            showUpgradePayModal={() =>
              dispatch(setShowPaymentModal({ showPaymentModal: true, upgradePlanId: PLANS.PRO_U.id }))
            }
            showDeleteAccountModal={this.onDeleteAccount}
            proMode
          />
          <UpgradePromoCard onClick={() => dispatch(showSubscribeModal(true))} trialAccount={trialAccount} />
          {trialAccount && <ProPlansModal email={userProfile.email} />}
          {fetchingUser ? (
            <Spinner className="mt-5" animation="border" variant="dark" />
          ) : (
            <>
              <div className={`container-manage ${trialAccount ? '' : 'd-block'}`}>
                <div className={`container-manage-left${trialAccount ? '' : '-full'}`}>
                  <BandwidthAlert
                    pageViews={userProfile.pageViews}
                    bandwidth={userProfile.bandwidth}
                    productId={userProfile.productId}
                    resetDate={userProfile.created}
                    usage_alert={userProfile.usage_alert}
                  />
                  <h2 className="text-center text-lg-start bold font-24">My Account</h2>
                  <div className="section">
                    <Tabs id="content-selector" defaultActiveKey="live" className="mb-4">
                      <Tab eventKey="live" title="Live">
                        <>
                          <ErrorBoundary fallback={fallbackComponent}>
                            <LiveSitesTable
                              sites={liveSites}
                              userProfile={userProfile}
                              onDelete={this.onDelete}
                              onDownload={this.onDownload}
                              onEdit={this.onEdit}
                              onSiteAction={this.onSiteAction}
                              onArchive={this.onArchive}
                              onAnalytics={this.onAnalytics}
                              trialAccount={trialAccount}
                              onQRCode={this.onQRCode}
                              onPreview={this.onPreview}
                              onEditPDF={this.onEditPDF}
                              onEmbed={this.onEmbed}
                              onShare={this.onShare}
                              onOpenTextEditor={this.onOpenTextEditor}
                              onShowCodeEditor={() => this.setState({ showCodeEditor: true })}
                              onAddCustomDomain={this.onAddCustomDomain}
                              badge={siteLimit === 999 ? null : `${liveSites.length} / ${siteLimit} live`}
                            />
                          </ErrorBoundary>
                        </>
                      </Tab>
                      <Tab eventKey="archive" title="Archive">
                        <ArchiveTable sites={archivedSites} onDeleteArchive={this.onDeleteArchive} />
                      </Tab>
                    </Tabs>
                  </div>
                  <div className="row">
                    <CustomDomain
                      customDomainsLimit={userProfile.customDomainsLimit}
                      customDomains={customDomains}
                      className="col-12 mt-5"
                      dispatch={dispatch}
                      productId={userProfile.productId}
                      trialAccount={trialAccount}
                      refreshProfile={this.refreshProfile}
                      onSiteAction={() => {
                        this.onSiteAction(ACTION_CREATE)
                      }}
                    />
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
        <UserFeedbackModal />
      </DragWrapper>
    )
  }
}

const mapStateToProps = ({ manage, upgrade }) => ({
  userProfile: manage.userProfile,
  archivedSites: manage.archivedSites,
  customDomains: manage.customDomains,
  members: manage.members?.map((m) => m.email),
  liveSites: manage.liveSites,
  actionProcessing: manage.actionProcessing,
  fetchingUser: manage.fetchingUser,
  accountModalVisible: manage.accountModalVisible,
  showProPlans: manage.upgradeModalVisible,
  modalMessageData: manage.modalMessageData,
  showMessageModal: manage.showMessageModal,
  showTeamModal: manage.showTeamModal,
  showFistTimeSuccModal: manage.showFistTimeSuccModal,
  QRCodeData: manage.QRCodeData,
  showQRCodeModal: manage.showQRCodeModal,
  embedData: manage.embedData,
  showPreviewModal: manage.showPreviewModal,
  PreviewData: manage.PreviewData,
  showPDFEditorModal: manage.showPDFEditorModal,
  showPaymentModal: upgrade.showPaymentModal,
  upgradePlanId: upgrade.upgradePlanId
})

const mapDispatchToProps = (dispatch) => ({ dispatch })

export default connect(mapStateToProps, mapDispatchToProps)(withGoogleReCaptcha(withRouter(Manage)))
