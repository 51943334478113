import React, { useEffect } from 'react'
import ReactDOM from 'react-dom'
import { Route } from 'react-router-dom'
import { ConnectedRouter } from 'connected-react-router'
import { createBrowserHistory } from 'history'
import { Provider } from 'react-redux'
import { Switch, useLocation } from 'react-router'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'
import CanonicalLink from 'components/CanonicalLink'

import MySites from './Manage'
import { store } from './store'
import Login from './Login'
import ProWelcome from './ProWelcome'
import Auth from './Auth'
import Pricing from './Pricing'
import Help from './Help'
import Landing from './Landing'
import { STRIPE_KEY } from './constants'
import NotFound from './components/NotFound'
import SupportUpload from './SupportUpload'
import ReportSite from './ReportSite'
import Affiliates from './Affiliates'
import Connect from './Connect'
import { isDevSite } from './utils/general'
import Examples from './Examples/Examples'
import FreeWelcome from './FreeWelcome'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'
import { reCAPTCHA_CLIENT } from './constants'
import API from './API'

import './index.css'

function ScrollToTop() {
  const { pathname } = useLocation()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])

  return null
}

const stripePromise = loadStripe(STRIPE_KEY)

const promoCodeCheck = () => {
  const urlParams = new URLSearchParams(window.location.search)
  const code = urlParams.get('promoCode')

  if (code) {
    window.localStorage.setItem('promoCode', code)
  }
}

const App = (props) => {
  !isDevSite() &&
    Sentry.init({
      dsn: 'https://e07d6e394f5b44e9a595102da16acb51@o383510.ingest.sentry.io/5213708',
      integrations: [new Integrations.BrowserTracing()],
      tracesSampleRate: 1.0
    })

  promoCodeCheck()

  return (
    <ConnectedRouter history={props.history}>
      <Elements stripe={stripePromise}>
        <ScrollToTop />
        <div className="App">
          <CanonicalLink history={props.history} />
          <Switch>
            <Route path="/" exact component={Landing} />
            <Route path="/login" exact component={Login} />
            <Route path="/api" exact component={API} />
            <Route path="/auth/:id" exact component={Auth} />
            <Route path="/manage" component={MySites} />
            <Route path="/pro-welcome" component={ProWelcome} />
            <Route path="/free-welcome" component={FreeWelcome} />
            <Route path="/pricing" component={Pricing} />
            <Route path="/examples/:type?" component={Examples} />
            <Route path="/help" component={Help} />
            <Route path="/support-upload" component={SupportUpload} />
            <Route path="/report-site" component={ReportSite} />
            <Route path="/affiliates" component={Affiliates} />
            <Route path="/connect" component={Connect} />
            <Route component={NotFound} />
          </Switch>
        </div>
      </Elements>
    </ConnectedRouter>
  )
}

const history = createBrowserHistory()

ReactDOM.render(
  <Provider store={store(history)}>
    <GoogleReCaptchaProvider reCaptchaKey={reCAPTCHA_CLIENT}>
      <App history={history} />
    </GoogleReCaptchaProvider>
  </Provider>,
  document.getElementById('root')
)
