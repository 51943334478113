import React from 'react'
import { connect } from 'react-redux'
import { Button, Modal, Container, Row, Col } from 'react-bootstrap'
import { withRouter } from 'react-router'

import { setQrCodeData, setQrCodeModal, setPreviewData, setPreviewModal } from 'Manage/actions'
import ShareBox from './components/ShareBox'
import { getDirectFileLink } from 'utils/general'
import { setShowPaymentModal, showUpgradeCardModal, UPGRADE_QR_PAYLOAD } from '../../actions'
import { PLANS } from '../../constants/plans'
import { QR_PAYWALL_DATE } from '../QRCodeModal/QRCodeModal'

import './SuccessBox.css'
import CustomTooltip from '../CustomTooltip'

export const UPGRADE_OPTIONS = {
  default: [
    {
      label: 'Edit code + HTML',
      icon: 'edit.svg',
      plan: PLANS.PRO.id,
      tip: 'Modify text, images, links & code in seconds online using our built-in editor'
    },
    {
      label: 'Link custom domain',
      icon: 'edit.svg',
      plan: PLANS.PRO.id,
      tip: 'Use your own domain name to publish content, custom SSL included'
    },
    {
      label: 'View analytics',
      icon: 'chart-bar.svg',
      plan: PLANS.TINY.id,
      tip: "View your project's the number of visitors & where they're coming from"
    }
  ],
  UPLOAD_PDF: [
    {
      label: 'Edit PDF text',
      icon: 'edit.svg',
      plan: PLANS.PRO.id,
      tip: 'Make corrections & add text in seconds online using our built-in editor'
    },
    {
      label: 'Set a password',
      icon: 'lock-outline.svg',
      plan: PLANS.PRO.id,
      tip: 'Restrict who can view your content by adding a password'
    },
    {
      label: 'Capture emails',
      icon: 'email.svg',
      plan: PLANS.PRO_U.id,
      tip: 'Capture potential leads by requiring visitors to enter their email address'
    }
  ],
  UPLOAD_ZIP: [
    {
      label: 'Link a custom domain',
      icon: 'edit.svg',
      plan: PLANS.PRO.id,
      tip: 'Use your own domain name to publish content, custom SSL included'
    },
    {
      label: 'Set a password',
      icon: 'lock-outline.svg',
      plan: PLANS.PRO.id,
      tip: 'Restrict who can view your content by adding a password'
    },
    {
      label: 'View analytics',
      icon: 'chart-bar.svg',
      plan: PLANS.TINY.id,
      tip: "View your project's the number of visitors & where they're coming from"
    }
  ]
}

const FirstTimeSuccessModal = ({ domain, userProfile, dispatch, show, onHide }) => {
  const freeAccount = !userProfile.productId

  const onUpgrade = (planId) => {
    dispatch(setShowPaymentModal({ showPaymentModal: true, upgradePlanId: planId }))
  }

  const getUpgradeOptions = () => {
    return UPGRADE_OPTIONS[domain?.siteType] || UPGRADE_OPTIONS.default
  }

  const onQRCode = () => {
    if (userProfile.productId || new Date(userProfile.created) < new Date(QR_PAYWALL_DATE)) {
      dispatch(setQrCodeModal(true))
      dispatch(setQrCodeData(domain.subdomain))
    } else {
      dispatch(
        showUpgradeCardModal({
          ...UPGRADE_QR_PAYLOAD,
          onClick: () => dispatch(setShowPaymentModal({ showPaymentModal: true, upgradePlanId: PLANS.TINY.id }))
        })
      )
    }
  }

  const onClose = () => {
    onHide()
  }

  const onViewSite = (site) => {
    if (site?.subdomain) window.open(`https://${site.subdomain}`, '_blank')
    else {
      dispatch(setPreviewModal(true))
      dispatch(setPreviewData(site))
    }
  }

  return (
    <Modal
      show={show}
      onHide={onClose}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      size={freeAccount ? 'lg' : 'md'}
      className="success_modal"
      enforceFocus={false}
    >
      <Container fluid>
        <Row className={freeAccount ? 'flex-column-reverse flex-lg-row' : ''}>
          {freeAccount && (
            <Col lg={5} className="bg-light-grey p-4 rounded slide-left z-0 position-relative">
              <h2 className="mb-2 color-purple-2 font-22 mb-lg-2">
                <b>Upgrade for more</b>
              </h2>
              <span className="success_subheader">Do more with your project.</span>
              <div className="mt-3">
                {getUpgradeOptions().map((option, index) => (
                  <CustomTooltip label={option.tip} placement="right">
                    <Button
                      key={index}
                      className="custom-button mt-3 w-100 text-start"
                      variant="light"
                      onClick={() => {
                        onUpgrade(option.plan)
                      }}
                    >
                      <img src={`assets/icons/${option.icon}`} height={16} alt={option.icon} />
                      <span className="success_subheader ms-2">{option.label}</span>
                    </Button>
                  </CustomTooltip>
                ))}
              </div>
            </Col>
          )}

          <Col
            lg={freeAccount ? 7 : 12}
            className={`p-4 bg-white ${freeAccount ? 'rounded-end' : 'rounded-2'} z-1 position-relative`}
          >
            <div className="success-box">
              <div className="float-right" onClick={onClose}>
                <img className="exit" src="delete.png" alt="quit" />
              </div>
              <h2 className="font-26 bold">Success</h2>
              <img className="celebrate" src="celebrate.gif" alt="celebrate" />
              <div>
                {domain?.subdomain ? (
                  <h5 className="domain">
                    <a href={`https://${domain?.subdomain}`} target="_blank" rel="noopener noreferrer">
                      {domain?.subdomain}
                    </a>
                  </h5>
                ) : (
                  <h5 className="domain">Your Site</h5>
                )}
                <p className="font-20">is live</p>
                <div className="d-flex flex-column mt-2 mb-3 align-items-center">
                  <div className="mt-2 d-flex align-items-center flex-row">
                    {/* <Button className="me-2" variant="outline-primary" onClick={onUpdateSite}>
                  Update
                </Button> */}
                    <Button onClick={() => onViewSite(domain)}>View Site</Button>
                  </div>
                  {domain?.subdomain && (
                    <ShareBox
                      trial={!!!userProfile.productId}
                      url={`https://${domain?.subdomain}`}
                      directFileUrl={getDirectFileLink(
                        `https://${domain?.subdomain}`,
                        domain?.filename,
                        'pdf',
                        domain?.lastUpdated || domain?.created
                      )}
                      onQRClick={onQRCode}
                      type={domain?.type}
                    />
                  )}
                  <div className="d-flex align-items-center justify-items-center mt-3 gap-1 font-small">
                    <span className="d-inline">Something broken? -</span>
                    <a
                      className="d-inline link"
                      href={`https://helpdesk.tiiny.host/en/category/common-issues-wocoo1/`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      Read our help guide
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </Modal>
  )
}

const mapDispatchToProps = (dispatch) => ({
  dispatch: dispatch
})

export default connect(mapDispatchToProps)(withRouter(FirstTimeSuccessModal))
