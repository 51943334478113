import React, { useEffect, useState } from 'react'
import { Button, Modal, Container, Row, Col } from 'react-bootstrap'
import { useHistory } from 'react-router-dom'

const APIWelcomeModal = ({ apiKey = 'XXXX-XXXXX' }) => {
  const [show, setShow] = useState(false)
  const [copied, setCopied] = useState(false)
  const history = useHistory()

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search)
    const apiWelcome = urlParams.get('api-welcome')

    if (apiWelcome === 'true') {
      setShow(true)
      urlParams.delete('api-welcome')
      history.replace({
        pathname: '/manage',
        search: urlParams.toString()
      })
    }
  }, [])

  const handleCopy = () => {
    navigator.clipboard.writeText(apiKey)
    setCopied(true)
    setTimeout(() => setCopied(false), 2000)
  }

  return (
    <Modal show={show} onHide={() => setShow(false)} aria-labelledby="contained-modal-title-vcenter" centered size="md">
      <Modal.Body className="p-4">
        <h2 className="mb-2 bold color-purple-2 font-26">You now have API access</h2>

        <Container className="mt-4">
          <Row>
            <Col>
              <h3 className="font-18 bold mb-2">Your API Key</h3>
              <div>Please keep this key secure and don't share it with others.</div>
              <div className="d-flex align-items-center bg-light p-3 rounded mt-3">
                <code className="flex-grow-1">{apiKey}</code>
                <Button variant={copied ? 'success' : 'secondary'} size="sm" onClick={handleCopy} className="ms-2">
                  {copied ? 'Copied!' : 'Copy'}
                </Button>
              </div>
              <p className="text-muted mt-2">
                You can always find your key in the "Manage account" section of the menu.
              </p>
            </Col>
          </Row>
        </Container>

        <Button
          variant="primary"
          href="https://api-docs.tiiny.co"
          target="_blank"
          rel="noopener noreferrer"
          className="w-100 mt-2"
        >
          View API Documentation
        </Button>
      </Modal.Body>
    </Modal>
  )
}

export default APIWelcomeModal
