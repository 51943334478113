import SuccessBox from 'components/CreateUpdateSiteModal/components/SuccessBox'
import React, { useEffect } from 'react'
import { Modal } from 'react-bootstrap'
import { useHistory } from 'react-router-dom'

const ShareModal = ({ show, onClose, userProfile, site, files, onReset }) => {
  const history = useHistory()

  const onHide = () => {
    history.push({ pathname: '/manage' })
    onClose()
  }

  useEffect(() => {
    if (show) {
      history.push({ pathname: '/manage/share' })
    }
  }, [show, history])

  const trialAccount = !userProfile.productId

  return (
    <Modal
      show={show}
      onHide={onClose}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      size={trialAccount ? 'lg' : 'md'}
      className="success_modal"
      enforceFocus={false}
    >
      <SuccessBox userProfile={userProfile} site={site} files={files} onClose={onClose} onReset={onReset} />
    </Modal>
  )
}

export default ShareModal
