import React, { useState, useEffect } from 'react'
import { showUpgradeModal } from '../../Manage/actions'
import ProPlans from './index'
import { Modal } from 'react-bootstrap'
import { connect } from 'react-redux'
import { SOURCE_LOGGED_IN } from '../../constants'
import { useHistory } from 'react-router-dom'

const ProPlansModal = ({ email, showProPlans, dispatch }) => {
  const history = useHistory()

  useEffect(() => {
    if (showProPlans) {
      const { pathname } = window.location
      if (pathname === '/manage/subscription/upgrade') {
        history.replace({ pathname: '/manage/subscription/upgrade' })
        return
      }

      if (pathname === '/manage/subscription/change') {
        history.replace({ pathname: '/manage/subscription/change' })
        return
      }

      history.replace({ pathname: '/manage/subscription/upgrade' })
    }
  }, [showProPlans, history])

  const onClose = () => {
    const { pathname } = window.location
    if (pathname === '/manage/subscription/upgrade' || pathname === '/manage/subscription/change') {
      history.push('/manage')
    }
  }

  return (
    <Modal
      aria-labelledby="contained-modal-title-vcenter"
      backdropClassName="upgrade-modal-backdrop"
      size="xl"
      onHide={() => {
        dispatch(showUpgradeModal(false))
        onClose()
      }}
      show={showProPlans}
      centered
      enforceFocus={false}
    >
      <div className={`plans-modal text-center ps-5 pe-5 pe-md-0 ps-md-0`}>
        <div
          className="cursor-pointer position-absolute"
          onClick={() => {
            dispatch(showUpgradeModal(false))
            onClose()
          }}
          style={{ top: 16, right: 20 }}
        >
          <img src="/assets/icons/cancel_black.svg" height={20} />
        </div>
        <h1 className="bold mt-5 font-38 color-purple">Upgrade for more</h1>
        <ProPlans
          className="w-100 p-5"
          email={email}
          onPaymentClickCallback={() => dispatch(showUpgradeModal(false))}
          onPaymentHideCallback={() => dispatch(showUpgradeModal(false))}
          source={SOURCE_LOGGED_IN}
          showModal
          hideFreeCard
        />
      </div>
    </Modal>
  )
}

const mapDispatchToProps = (dispatch) => ({ dispatch })
const mapStateToProps = ({ manage }) => ({
  showProPlans: manage.upgradeModalVisible
})

export default connect(mapStateToProps, mapDispatchToProps)(ProPlansModal)
