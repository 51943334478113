import React from 'react'
import styles from './CustomTable.module.css'
import IconButton from '../IconButton'
import StatusCell from './components/StatusCell'
import DomainCell from './components/DomainCell'
import CustomTableHeader from './components/CustomTableHeader'
import OverflowMenu from './components/OverflowMenu/OverflowMenu.js'
import SearchBar from './SearchBar/SearchBar.js'
import useCheckIsMobileScreen from '../../hooks/checkMobileScreen'
import MenuItem from './components/MenuItem'
import { Button } from 'react-bootstrap'

const TableHeader = ({ header }) => (
  <th>
    <span className={styles.headerText}>{header.text}</span>
    {header.icon && <img src={header.icon} alt={header.text} className={styles.headerIcon} />}
  </th>
)

const TableCell = ({ content, cellType, isMobile, isTablet }) => {
  switch (cellType) {
    case 'status':
      return (
        <td className={styles.tableCell}>
          <StatusCell status={content?.status} text={content?.text} passwordProtected={content.passwordProtected} />
        </td>
      )
    case 'domain':
      return (
        <td className={styles.tableCell}>
          <DomainCell {...content} />
        </td>
      )
    case 'normal_text':
      return (
        <td className={styles.tableCell}>
          <span className={styles.normalText}>{content}</span>
        </td>
      )
    case 'actions':
      if (isMobile || isTablet) {
        return (
          <td className={styles.buttonGroup}>
            <MenuItem content={content} icon={'/assets/icons/menu_horizontal.svg'} />
          </td>
        )
      } else {
        return (
          <td className={styles.buttonGroup}>
            {content.map((button, index) => {
              if (button.type === 'action_menu') {
                return <OverflowMenu key={index} {...button.props} />
              }
              if (button.type === 'custom_button') {
                return <>{button.component}</>
              }
              return (
                <IconButton
                  key={index}
                  src={button.icon}
                  onClick={button.onClick}
                  disabled={button.disabled}
                  tooltip={button.tooltip}
                  link={button.link}
                  className={styles.actionButton}
                  label={button.label}
                />
              )
            })}
          </td>
        )
      }

    case 'link':
      return (
        <td className={styles.tableCell}>
          <a href={content} target="_blank" rel="noopener noreferrer">
            {content}
          </a>
        </td>
      )
    case 'lastModified':
      return (
        <td>
          <span className={styles.normalText}>{content}</span>
        </td>
      )
    default:
      if (React.isValidElement(content)) {
        return (
          <td>
            <span className={styles.normalText}>{content}</span>
          </td>
        )
      }
      return (
        <td>
          <span className={styles.normalText}>{String(content)}</span>
        </td>
      )
  }
}

const CustomTable = ({
  headers,
  data,
  title,
  badge,
  headerButtons,
  emptyStateMessage,
  emptyStateButton,
  onSearch,
  searchTerm,
  showSearch = false
}) => {
  const { isMobile, isTablet } = useCheckIsMobileScreen()
  return (
    <div className={styles.tableWrapper}>
      <CustomTableHeader title={title} badge={badge} buttons={headerButtons} />
      <SearchBar onSearch={onSearch} searchTerm={searchTerm} show={showSearch} />
      {data.length > 0 ? (
        <div className={styles.tableContainer}>
          <table className={styles.table}>
            <thead className={styles.tableHeaderWrapper}>
              <tr className={styles.tableHeader}>
                {headers.map((header, index) => (
                  <TableHeader key={index} header={header} />
                ))}
              </tr>
            </thead>
            <tbody className={styles.tableBody}>
              {data.map((row, rowIndex) => (
                <tr key={rowIndex} className={styles.tableRow} tabIndex={0}>
                  {headers.map((header, cellIndex) => (
                    <TableCell
                      key={cellIndex}
                      content={row[header.key]}
                      cellType={header.cellType}
                      isMobile={isMobile}
                      isTablet={isTablet}
                    />
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        <div className={styles.emptyState}>
          <span className={styles.emptyStateMessage}>{emptyStateMessage || 'No data available'}</span>
          {emptyStateButton && (
            <Button onClick={emptyStateButton.onClick} variant="outline-primary" className={styles.uploadButton}>
              {emptyStateButton.text || 'Upload'}
            </Button>
          )}
        </div>
      )}
    </div>
  )
}

export default CustomTable
