import React from 'react'
import { Nav, NavDropdown } from 'react-bootstrap'
import styles from '../Header.module.css'
import { isMac } from '../../../../utils/general'
import { Item, NavItem } from '../Header'

const IntegrationsDropdown = () => (
  <Nav.Item className="integration-tab">
    <NavDropdown
      className="p-md-0 m-auto dropdown-toggle"
      title={<NavItem icon="power.svg" alt="Integrations" label="Integrations" target="_blank" />}
      variant="link"
    >
      <div className={styles.menu}>
        <div className={styles.contentContainer}>
          <div className={styles.twoColumn}>
            <Item
              icon="cog.svg"
              alt="API"
              label="API"
              href="https://tiiny.host/api"
              target="_blank"
              subtitle="Upload via the Tiiny API"
            />
            <Item
              icon="openai.svg"
              alt="chatGPT"
              label="ChatGPT"
              href="https://chatgpt.com/g/g-6731c3a9f8a48190b445f7d2c8d4f817-app-website-generator-by-tiinyhost"
              target="_blank"
              subtitle="Build apps & websites"
            />
            <Item
              icon="zapier.png"
              alt="Zapier"
              label="Zapier"
              href="https://zapier.com/apps/tiiny-host/integrations"
              target="_blank"
              subtitle="Upload via automations"
            />
            <Item
              icon="chrome.svg"
              alt="Google Chrome"
              label="Google Chrome"
              href="https://chromewebstore.google.com/detail/tiny-host/meippdeigckopliglcajepbiaoaccflc"
              target="_blank"
              subtitle="Upload from your browser"
            />
            <Item
              icon="vs-code.png"
              alt="VS Code"
              label="VS Code"
              href="https://marketplace.visualstudio.com/items?itemName=TiinyHost.tiinyhost"
              target="_blank"
              subtitle="Upload from your IDE"
            />
            {isMac() && (
              <Item
                icon="apple.svg"
                alt="Mac OS"
                label="Mac OS"
                href="https://tiiny.host/mac/plugin.dmg"
                target="_blank"
                subtitle="Upload from your desktop"
              />
            )}
          </div>
        </div>
      </div>
    </NavDropdown>
  </Nav.Item>
)

export default IntegrationsDropdown
