import React from 'react'
import { Nav, NavDropdown } from 'react-bootstrap'
import styles from '../Header.module.css'
import { Item, NavItem } from '../Header'

const HelpDropdown = ({ hasAPIAccess }) => (
  <Nav.Item className="integration-tab">
    <NavDropdown
      className="p-md-0 m-auto dropdown-toggle"
      title={<NavItem icon="help.svg" alt="Help" label="Help" target="_blank" />}
      variant="link"
    >
      <div className={styles.menu}>
        <div className={styles.contentContainer}>
          <Item
            icon="help-desk.svg"
            alt="Help Desk"
            label="Help Desk"
            href="https://helpdesk.tiiny.host"
            target="_blank"
          />
          <Item
            icon="support.svg"
            alt="Support"
            label="Support"
            onClick={() => window.$crisp.push(['do', 'chat:open'])}
          />
          {hasAPIAccess && (
            <Item icon="cog.svg" alt="API Docs" label="API Docs" href="https://api-docs.tiiny.co" target="_blank" />
          )}
        </div>
      </div>
    </NavDropdown>
  </Nav.Item>
)

export default HelpDropdown
