import React from 'react'
import { PLANS } from '../../../constants/plans'
import { ToolTip } from '../../ProPlans/PlanCards'
import { DOWNGRADE } from '../UpgradePayModal'

const FeatureList = ({ planId, stage, list }) => {
  return (
    <>
      {stage === DOWNGRADE &&
        list.map((feature) => {
          return (
            <div className="flex pt-2 ms-3 align-items-center opacity-80" key={feature.label}>
              <img className="me-2" height="15px" width="15px" alt="check" src="/assets/icons/x.png" />
              {feature?.label}
            </div>
          )
        })}
      {stage !== DOWNGRADE && planId === PLANS.PRO_U.id && (
        <>
          <div className="flex pt-3 ms-3 align-items-top bold">
            <img className="me-2 mt-1" height="15px" width="15px" alt="check" src="/check.png" /> Upload unlimited
            projects
          </div>
          <div className="flex pt-2 ms-3 align-items-top bold">
            <img className="me-2 mt-1" height="15px" width="15px" alt="check" src="/check.png" /> Add up to two more
            team members <ToolTip text="Add more team members for +$5 each" />
          </div>
          <div className="flex pt-2 ms-3 align-items-center">
            <img className="me-2" height="15px" width="15px" alt="check" src="/check.png" /> Upload more than 75mb files
          </div>
          <div className="flex pt-2 ms-3 align-items-center">
            <img className="me-2" height="15px" width="15px" alt="check" src="/check.png" /> All Solo & Tiny plan
            features
          </div>
        </>
      )}
      {stage !== DOWNGRADE && planId === PLANS.PRO.id && (
        <>
          <div className="flex pt-3 ms-3 align-items-center bold">
            <img className="me-2" height="15px" width="15px" alt="check" src="/check.png" /> Connect your custom domain
          </div>
          <div className="flex pt-2 ms-3 align-items-center">
            <img className="me-2" height="15px" width="15px" alt="check" src="/check.png" /> Upload more than{' '}
            {PLANS.TINY.fileSizeLimit}mb files
          </div>
          <div className="flex pt-2 ms-3 align-items-center">
            <img className="me-2" height="15px" width="15px" alt="check" src="/check.png" /> Upload more than 1 project
          </div>
          <div className="flex pt-2 ms-3 align-items-center">
            <img className="me-2" height="15px" width="15px" alt="check" src="/check.png" /> Edit your content
          </div>
          <div className="flex pt-2 ms-3 align-items-center opacity-50">
            <img className="me-2" height="15px" width="15px" alt="check" src="/assets/icons/x.png" /> Add team members
          </div>
        </>
      )}
      {stage !== DOWNGRADE && planId === PLANS.TINY.id && (
        <>
          <div className="flex pt-3 ms-3 align-items-top bold">
            <img className="me-2" height="15px" width="15px" alt="check" src="/check.png" /> 1 active project
          </div>
          <div className="flex pt-2 ms-3 align-items-center">
            <img className="me-2" height="15px" width="15px" alt="check" src="/check.png" /> Upload up to{' '}
            {PLANS.TINY.fileSizeLimit}mb files
          </div>
          <div className="flex pt-2 ms-3 align-items-center">
            <img className="me-2" height="15px" width="15px" alt="check" src="/check.png" /> Remove Tiiny Host banner
          </div>
          <div className="flex pt-2 ms-3 align-items-center">
            <img className="me-2" height="15px" width="15px" alt="check" src="/check.png" /> Built in analytics
          </div>
          <div className="flex pt-2 ms-3 align-items-center">
            <img className="me-2" height="15px" width="15px" alt="check" src="/check.png" /> Upt to 10,000 visitors/mo
          </div>
          <div className="flex pt-2 ms-3 align-items-center opacity-50">
            <img className="me-2" height="15px" width="15px" alt="check" src="/assets/icons/x.png" /> Connect custom
            domain
          </div>
        </>
      )}
      {stage !== DOWNGRADE && planId === PLANS.API.id && (
        <>
          <div className="flex pt-3 ms-3 align-items-center bold">
            <img className="me-2" height="15px" width="15px" alt="check" src="/check.png" /> Unlimited links & API calls
          </div>
          <div className="flex pt-2 ms-3 align-items-center">
            <img className="me-2" height="15px" width="15px" alt="check" src="/check.png" /> Upload upto 75mb files
          </div>
          <div className="flex pt-2 ms-3 align-items-center">
            <img className="me-2" height="15px" width="15px" alt="check" src="/check.png" /> Upgradeable 5 GB account
            limit
          </div>
          <div className="flex pt-2 ms-3 align-items-center">
            <img className="me-2" height="15px" width="15px" alt="check" src="/check.png" /> Pro plan account
          </div>
          <div className="flex pt-2 ms-3 align-items-center">
            <img className="me-2" height="15px" width="15px" alt="check" src="/check.png" /> Dedicated technical support
          </div>
        </>
      )}
      {planId !== PLANS.API.id && (
        <div className="mt-3 font-16 ms-3">
          <a className="color-purple" href="https://tiiny.host/pricing" target="_blank" rel="noreferrer">
            Compare plans →
          </a>
        </div>
      )}
    </>
  )
}

export default FeatureList
