import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Field, ErrorMessage } from 'formik'
import { DropdownButton, FormControl, InputGroup } from 'react-bootstrap'
import { F_EMAIL_GATE, F_PWD_PROTECTION } from 'constants/plans/constants'
import SiteOption from './foundations/SiteOption'
import { hasFeature, isPhpFile } from 'utils/general'
import {
  DISPLAY_MODE_PRESENTATION,
  DISPLAY_MODE_STANDARD,
  GATED_MODE_EMAIL_GATE,
  GATED_MODE_PASSWORD,
  GATED_MODE_STANDARD,
  MODES,
  PDF_FILE,
  ZIP_FILE
} from 'components/CreateUpdateSiteModal/interface'
import { ToolTip } from 'components/ProPlans/PlanCards'
import SelectItem from './foundations/SelectItem'
import { setShowPaymentModal } from 'actions'
import { PLANS } from 'constants/plans'

const ToggleButtonsForm = ({ name, initialValues, userProfile, uploadValues, setFieldValue }) => {
  const dispatch = useDispatch()
  const {
    displayMode,
    gatedMode,
    passwordProtected,
    password,
    disableIndexing,
    disableExportPdf,
    siteConfiguration,
    errorPath
  } = initialValues
  const { productId } = userProfile
  const { selectedFile, addedFiles, fileType } = uploadValues

  useEffect(() => {
    if (siteConfiguration === true) {
      setFieldValue(`${name}.errorPath`, '/index.html')
    } else if (siteConfiguration === false) {
      setFieldValue(`${name}.errorPath`, '/')
    }
  }, [siteConfiguration])

  const onUpgrade = (planId) => {
    dispatch(setShowPaymentModal({ showPaymentModal: true, upgradePlanId: planId }))
  }

  return (
    <>
      {/** ACCESS GATE DROPDOWN */}
      {(selectedFile || fileType) && !isPhpFile(selectedFile) && (
        <div className="align-items-center w-100 ps-3 pe-3 pb-3">
          <div className="light-grey flex-grow-1">
            Access gate
            <ToolTip text="Choose how your content is restricted for visitors" />
          </div>
          <DropdownButton
            className="d-grid bg-light-grey round-8 mt-3 ms-2 me-2 mb-0 text-start"
            title={
              <span className="d-inline-flex" style={{ width: '95%' }}>
                <img className="pt-1 me-2 op-6" src={`/assets/icons/${MODES[gatedMode].icon}`} height={22} />
                {gatedMode}
              </span>
            }
            onSelect={(value) => {
              setFieldValue(`${name}.gatedMode`, value)
              setFieldValue(`${name}.passwordProtected`, value === GATED_MODE_PASSWORD)
            }}
            variant="outline"
          >
            <SelectItem label={GATED_MODE_STANDARD} />
            <SelectItem
              label={GATED_MODE_PASSWORD}
              trial={!hasFeature(productId, F_PWD_PROTECTION)}
              learnLink="https://www.youtube.com/watch?v=rod_2W3GAGQ"
              onUpgrade={() => onUpgrade(PLANS.PRO.id)}
            />
            <SelectItem
              label={GATED_MODE_EMAIL_GATE}
              trial={!hasFeature(productId, F_EMAIL_GATE)}
              learnLink="https://www.youtube.com/watch?v=tlaDPsCF4Wo"
              onUpgrade={() => onUpgrade(PLANS.PRO_U.id)}
            />
          </DropdownButton>
        </div>
      )}
      {/** END ACCESS GATE DROPDOWN */}

      {/* PASSWORD */}
      {(selectedFile || fileType) && passwordProtected && !isPhpFile(selectedFile) && (
        <div className="ps-5 pe-5 pb-3">
          <InputGroup className="flex" size="sm">
            <InputGroup.Text>Password</InputGroup.Text>
            <Field
              name={`${name}.password`}
              value={password}
              type="text"
              as={FormControl}
              placeholder="Password"
              aria-label="Password"
              aria-describedby="inputGroupPrepend"
            />
          </InputGroup>
          <ErrorMessage name={`${name}.password`} component="div" className="validation-error text-center" />
        </div>
      )}
      {/* END PASSWORD */}

      {(selectedFile || fileType) && !isPhpFile(selectedFile) && (
        <div className="ps-3 pe-3 pb-3">
          <div className="light-grey flex align-items-center">Settings</div>
          <div className="p-2 align-content-center m-auto">
            {/* START PRESENTATION MODE */}
            {fileType === PDF_FILE && addedFiles.length === 0 && (
              <SiteOption
                icon="presentation.svg"
                label="Presentation mode"
                desc="Displays PDF as a slideshow"
                trial={!productId}
                onChange={() =>
                  setFieldValue(
                    `${name}.displayMode`,
                    displayMode === DISPLAY_MODE_PRESENTATION ? DISPLAY_MODE_STANDARD : DISPLAY_MODE_PRESENTATION
                  )
                }
                checked={displayMode === DISPLAY_MODE_PRESENTATION}
                onUpgrade={() => onUpgrade(PLANS.TINY.id)}
              />
            )}
            {/* END PRESENTATION MODE */}

            {/* HIDE FROM SEARCH ENGINE TOGGLE */}
            <SiteOption
              icon="hide.svg"
              label="Hide from Search Engines"
              desc="Prevent link from being indexed by search engines"
              trial={!productId}
              onChange={() => setFieldValue(`${name}.disableIndexing`, !disableIndexing)}
              checked={disableIndexing}
              onUpgrade={() => onUpgrade(PLANS.TINY.id)}
            />
            {/* END HIDE FROM SEARCH ENGINE TOGGLE */}

            {/* ERROR CONFIGURATION / ERROR PATH TOGGLE */}
            {fileType === PDF_FILE && (
              <SiteOption
                icon="no-download.svg"
                label="Disable PDF downloads"
                desc="Prevent visitors from downloading or printing your PDF"
                trial={!productId}
                onChange={() => setFieldValue(`${name}.disableExportPdf`, !disableExportPdf)}
                checked={disableExportPdf}
                onUpgrade={() => onUpgrade(PLANS.PRO.id)}
              />
            )}
            {/* END ERROR CONFIGURATION */}

            {/* ERROR PATH TOGGLE */}
            {fileType === ZIP_FILE && (
              <>
                <SiteOption
                  icon="cross.svg"
                  label="Error configuration"
                  desc="Add a path to a custom 404 error page when files not found"
                  trial={!productId}
                  onChange={() => setFieldValue(`${name}.siteConfiguration`, !siteConfiguration)}
                  checked={siteConfiguration}
                  onUpgrade={() => onUpgrade(PLANS.PRO.id)}
                >
                  <>
                    <InputGroup size="sm" className="p-3">
                      <InputGroup.Text>Error Path</InputGroup.Text>
                      <Field
                        name={`${name}.errorPath`}
                        type="text"
                        as={FormControl}
                        placeholder="/index.html"
                        aria-label="error-path"
                        aria-describedby="inputGroupPrepend"
                        value={errorPath}
                      />
                    </InputGroup>
                    <ErrorMessage name={`${name}.errorPath`} component="div" className="validation-error" />
                  </>
                </SiteOption>
              </>
            )}
            {/* END ERROR PATH TOGGLE  */}
          </div>
        </div>
      )}
    </>
  )
}

export default ToggleButtonsForm
